import LeaderBoardModel from "../../model/LeaderBoardModel"
import "./LeaderBoardModule.css"
import React from "react"
import { Link } from "react-router-dom"
import { EventEmitter } from "../../events"

// Import images
import secondPro from "../../assets/img/second-pro.svg"
import crown from "../../assets/img/crown.svg"
import blocks from "../../assets/img/123-blocks.gif"

export default class LeaderBoardModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      leaderBoard: [],
      currentUserRank: {},
    }

    this.leaderBoardModel = new LeaderBoardModel()

    this.fetchAllTimeLeaderBoard = this.fetchAllTimeLeaderBoard.bind(this)
    this.switchLeaderBoard = this.switchLeaderBoard.bind(this)
  }

  componentDidMount() {
    this.fetchAllTimeLeaderBoard()

    EventEmitter.subscribe("fetchLeaderBoard", () => {
      this.fetchAllTimeLeaderBoard()
    })
  }

  async fetchAllTimeLeaderBoard() {
    const leaderBoard = await this.leaderBoardModel.GetAllTimeLeaderBoard()
    if (leaderBoard) {
      this.setState({
        leaderBoard: leaderBoard.other_users_rank || [], //tina
        currentUserRank: leaderBoard.my_rank,
      })
    }
  }

  switchLeaderBoard(type) {
    let data = {
      currentUserRank: this.state.currentUserRank,
      leaderBoard: this.state.leaderBoard || [], //tina
      type: type,
    }

    this.props.onSwitch(data)
  }

  formatNumber(number) {
    if (number === undefined) {
      return 0
    }
    number = parseInt(number, 10)
    let newNum = number.toLocaleString("en-US", {
      notation: "compact",
      compactDisplay: "short",
    })

    console.log("newNum", newNum)

    return newNum
  }

  render() {
    return (
      <>
        <div className="points">
          <a
            href="!#"
            data-bs-toggle="modal"
            data-bs-target="#LeaderBoard"
            onClick={() => {
              this.switchLeaderBoard("weekly")
            }}>
            <div key={`weekly1`} className="but">
              weekly
            </div>
          </a>
          <a
            href="!#"
            data-bs-toggle="modal"
            data-bs-target="#LeaderBoard"
            onClick={() => {
              this.switchLeaderBoard("all_time")
            }}>
            <div key={`alltimes1`} className="but">
              all times
            </div>
          </a>
          <ul>
            <li
              className="player2"
              style={
                this.state.leaderBoard?.length <= 1
                  ? { visibility: "hidden" }
                  : {}
              }>
              <span>2</span>
              <img alt="" className="avatar2" src={secondPro} />
              <div className="name">
                <Link to={`/profile/${this.state.leaderBoard[1]?.user_id}`}>
                  {this.state.leaderBoard[1]?.name}
                </Link>
              </div>
              <div className="point">
                {this.formatNumber(this.state.leaderBoard[1]?.score)}
              </div>
            </li>
            <li
              className="player1"
              style={
                this.state.leaderBoard.length <= 0
                  ? { visibility: "hidden" }
                  : {}
              }>
              <img alt="" className="crown" src={crown} />
              <img alt="" className="avatar1" src={secondPro} />
              <div className="name">
                <Link to={`/profile/${this.state.leaderBoard[0]?.user_id}`}>
                  {this.state.leaderBoard[0]?.name}
                </Link>
              </div>
              <div className="point">
                {this.formatNumber(this.state.leaderBoard[0]?.score)}
              </div>
            </li>
            <li
              className="player3"
              style={
                this.state.leaderBoard.length <= 2
                  ? { visibility: "hidden" }
                  : {}
              }>
              <span>3</span>
              <img alt="" className="avatar3" src={secondPro} />
              <div className="name">
                <Link to={`/profile/${this.state.leaderBoard[2]?.user_id}`}>
                  {this.state.leaderBoard[2]?.name}
                </Link>
              </div>
              <div className="point">
                {this.formatNumber(this.state.leaderBoard[2]?.score)}
              </div>
            </li>
          </ul>
          <div className="position-block">
            <img alt="" src={blocks} />
          </div>
        </div>
      </>
    )
  }
}

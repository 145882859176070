import React from "react"
import UserModel from "../../../../model/UserModel"
import { PulseLoader } from "react-spinners"
import Follower from "./Follower"
import { ListGroup } from "react-bootstrap"


export default class Followers extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			followersList: [],
			page: 1,
			limit: 7,
			hasMore: true,
			loading: false,
		}
		this.userModel = new UserModel()

		this.fetchFollowersList = this.fetchFollowersList.bind(this)
	}

	async fetchFollowersList() {
		this.setState({ loading: true }, async () => {
			const resp = await this.userModel.GetFollowersList()
			// console.log("resp.data of followers", resp)

			// const followers = {
			// 	followers: [
			// 		{
			// 			follower_id: 1,
			// 			name: "John",
			// 		},
			// 		{
			// 			follower_id: 2,
			// 			name: "Doe",
			// 		},
			// 	]
			// }

			this.setState(
				{
					followersList: resp || [], //tina
					loading: false,
					// page: 2,
					// pageLoadedForFirstTime: false,
				},
				async () => {
					// Initialize Swiper
				}
			)
		})
	}

	render() {

		let loader = ""
		if (this.state.loading) {
			loader = (
				<PulseLoader
					loading="true"
					color="grey"
					aria-label="Loading Spinner"
					data-testid="loader"></PulseLoader>
			)
		} else {

		}

		return (
			<>
				<div className="followers-list">
					{loader}
					<ListGroup>
						{this.state.followersList && this.state.followersList.followers && this.state.followersList.followers.length > 0 ? (

							this.state.followersList.followers.map((follower) => (
								<React.Fragment key={follower.ID}>
									<Follower follower={follower} fetchFollowersList={this.fetchFollowersList} />
								</React.Fragment>
							))
						) : (
							<h5 style={{ marginTop: '20px' }}>You have not followed any profile</h5> // Message displayed when no followers are available
						)}
					</ListGroup>
				</div>
			</>
		)
	}
}

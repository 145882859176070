import ConfigModel from "./ConfigModel";
import UserModel from "./UserModel";

export default class LeaderBoardModel {
    configModel
    userModel
    constructor() {
        this.configModel = new ConfigModel()
        this.userModel = new UserModel()
    }

    async GetAllTimeLeaderBoard() {
        let url = `${this.configModel.api}get_globle_leaderboard`
        let data = {
            access_key: "8525"
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            if (!response_data.error) {
                return response_data.data
            } else {
                return []
            }
        } catch (err) {
            console.error("post request failed ", err)
            return [] //tina
        }

    }

    async GetWeeklyLeaderBoard() {
        let url = `${this.configModel.api}get_weekly_leaderboard`
        let data = {
            access_key: "8525"
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }

    }

    async GetMonthlyLeaderBoard() {
        let url = `${this.configModel.api}get_monthly_leaderboard`
        let data = {
            access_key: "8525"
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }

    }

    async GetContestLeaderBoard(contest_id) {
        let url = `${this.configModel.api}get_contest_leaderboard`
        let data = {
            access_key: "8525",
            contest_id: contest_id
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            if (!response_data.error) {
                return response_data.data
            } else {
                return []
            }
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }

    }
}
import React from "react"

import profileAvatar from "../../../../assets/img/profile-avatar.jpg"
import UserModel from "../../../../model/UserModel"
import { ListGroup, Spinner } from "react-bootstrap"

export default class Follower extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			follower: this.props.follower,
			showConfirmation: false,
			isLoading: false,
		}
		this.userModel = new UserModel()

		this.setFollowingUnfollowing = this.setFollowingUnfollowing.bind(this)
		this.handleUnfollowClick = this.handleUnfollowClick.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleProceedClick = this.handleProceedClick.bind(this);
	}

	async setFollowingUnfollowing(follow_id) {
		let follow = 0
		await this.userModel.SetFollowUnfollow(
			follow_id,
			follow
		)

		this.props.fetchFollowersList()
	}

	handleUnfollowClick() {
		this.setState({ showConfirmation: true });
	}

	handleCancelClick() {
		this.setState({ showConfirmation: false });
	}

	async handleProceedClick() {
		this.setState({ isLoading: true }, async () => {
			await this.setFollowingUnfollowing(this.state.follower.follower_id);
			this.setState({ isLoading: false, showConfirmation: false });
		});
	}

	render() {
		let follower = this.state.follower

		return (
			<>
				<ListGroup.Item className="follower-item">
					<div className="follower-container">
						<img
							src={profileAvatar}
							className="follower-image" alt="Profile pic" />
						<span className="follower-name">{follower.name}</span>

						{this.state.showConfirmation ? (
							<div className="unfollow-confirmation">
								<button
									type="button"
									className="btn btn-danger btn-pill proceed-btn me-2"
									onClick={this.handleProceedClick}
									disabled={this.state.isLoading}
								>
									{this.state.isLoading ? (
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
									) : (
										'Proceed'
									)}
								</button>
								<button
									type="button"
									className="btn btn-secondary btn-pill cancel-btn"
									onClick={this.handleCancelClick}
								>
									Cancel
								</button>
							</div>
						) : (
							<button
								type="button"
								className="btn btn-outline-danger btn-pill unfollow-btn"
								onClick={this.handleUnfollowClick}
							>
								Unfollow
							</button>
						)}
					</div>
				</ListGroup.Item>
			</>
		)
	}
}
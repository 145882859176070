import "./PublicProfile.css"
import React from "react"
import { useParams } from "react-router-dom"
import UserModel from "../../model/UserModel"
import StudentSubmissionModel from "../../model/StudentSubmissionModel"
import PublicProfileSlider from "./PublicProfileSlider/PublicProfileSlider"
import { Link } from "react-router-dom"

// Image imports
import profileAvatarImg from "../../assets/img/profile-avatar.jpg"
import backtowImg from "../../assets/img/backtow.svg"

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />
}

class PublicProfile extends React.Component {
  constructor(props) {
    super(props)

    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("token")

    this.state = {
      token: token,
      userId: undefined,
      user: undefined,
      innovations: [],
      index: 0,
      initialized: false,
      loading: false,   
      followButtonVisible: false,    
      // isFollowing: false, //updated by Tina on 29 Oct24
    }

    this.userModel = new UserModel()
    this.submissionModel = new StudentSubmissionModel()

    this.fetchProfileData = this.fetchProfileData.bind(this)
    this.getAuthUserData = this.getAuthUserData.bind(this)
    this.setFollowingUnfollowing = this.setFollowingUnfollowing.bind(this) //updated by Tina on 29 Oct24
    this.getFollowingStatus = this.getFollowingStatus.bind(this) //updated by Tina on 29 Oct24
  }

  async getAuthUserData() {
    let userData = await this.userModel.AuthenticateUser(this.state.token)
    if (userData) {
      this.fetchProfileData()
    }
  }

  async fetchProfileData() {
    let user = await this.userModel.FetchUserProfile(this.state.userId)
    console.log('fetchuser:', user)
    this.setState({
      user: user,
      initialized: true,
      
    })
    this.getFollowingStatus() //updated by Tina on 29 Oct24
    
  }

  componentDidMount() {
    this.setState(
      {
        userId: this.props.params.id,
        
      },
      () => {
        if (this.state.token) {
          this.getAuthUserData()          
        } else {
          this.fetchProfileData()         
        }
        // this.setState({followButtonVisible: false});
      }
    )
    
  }

  calculateAge(dob) {
    let ageDifMs = Date.now() - new Date(dob)
    let ageDate = new Date(ageDifMs) // milliseconds from epoch
    return isNaN(Math.abs(ageDate.getUTCFullYear() - 1970))
      ? 0
      : Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  // updated by Tina on 29 Oct24 starts
  async getFollowingStatus() {
    let follow_id =  this.state.user ? this.state.user : this.state.userId;
    console.log('user', this.state.user, 'useid',this.state.userId);
    let followersList = await this.userModel.GetFollowersList(); // Ensure this is awaited if it's async
    console.log('followersList:', followersList);
    let isFollowing = false;  // Default is not following
    
    // Check if followersList is an object and contains an array of followers
    if (followersList && Array.isArray(followersList.followers)) { // Adjust 'data' if needed
        followersList.followers.forEach((follower) => {
          console.log('id:', follow_id, follower.follower_id, 'status:', follower.follow_status,true);  
            if (String(follower.follower_id) === String(follow_id) && follower.follow_status) {
              
              isFollowing = true;
            }
        });
    } else {
        console.error('followersList is not in the expected format or does not contain an array:', followersList);
    }
    
    this.setState({ isFollowing: isFollowing });
    this.setState({followButtonVisible: true});

    return isFollowing;    
}


  async setFollowingUnfollowing(){
        
    this.setState({ loading: true });  // Set loading to true before making the request
  
    let follow_id = this.state.userId
    console.log('follow_id set:', follow_id);
    let follow = this.state.isFollowing ? 0 : 1
    let button_state = await this.userModel.SetFollowUnfollow(
      follow_id,
      follow
    )
    if (button_state) {
      this.setState({
        isFollowing: !this.state.isFollowing
                
      })
    }
    this.setState({ loading: false }); // Set loading to false after the request is complete
    this.setState({followButtonVisible: true});
    
  }

  // updated by Tina on 29 Oct24 ends

  render() {
    let age = this.calculateAge(this.state.user?.student_dob)
    // this.state.isFollowing = this.getFollowingStatus() //updated by Tina on 29 Oct24
    return (
      <>
        <div className="container boxes profile">
          <div className="row">
            <div className="col-md-12">
              <div className="card mt-5">
                <div className="card-body content">
                  {(() => {
                    if (this.state.initialized) {
                      return (
                        <>
                          <div className="left">
                            <div className="l-l">
                              <img alt="User Icon" src={profileAvatarImg} />
                            
                              {/* updated by Tina on 29 Oct24 - added button to toggle between follow and unfollow */}
                            
                              <div className="follow-button-container mt-3">
                              {this.state.followButtonVisible && (<button
                                  type="button"
                                  className= {`btn btn-pill ${
                                    this.state.isFollowing ? "btn-danger" : "btn-primary"
                                  }`}
                                  onClick={this.setFollowingUnfollowing}
                                  visiblity={this.state.followButtonVisible}
                                  disabled={this.state.loading}  // Disable button when loading
                                > 
                                  <div className="button-content" >
                                    {this.state.loading ? (
                                      <><div className="loader"> </div> 
                                        <span disabled= "true">
                                          {this.state.isFollowing ? "Unfollow" : "Follow"}
                                        </span>
                                        </>
                                      
                                    ) : (
                                      this.state.isFollowing ? "Unfollow" : "Follow"
                                    )}
                                  </div>
                                  
                                </button> )}
                              </div>
                            </div>
                            <div className="l-r">
                              <h1>{this.state.user?.name}</h1>
                              <span>{age} Years</span>
                              <div className="clearfix"></div>
                              <ul>
                                <li>
                                  <h5>School Name</h5>
                                  <p>{this.state.user?.school_name}</p>
                                </li>
                                <li>
                                  <h5>Class & Division</h5>
                                  <p>{this.state.user?.class_name}</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="right">
                            <ul>
                              <li>
                                <h5>
                                  All Time Rank:{" "}
                                  <span className="wins">
                                    {this.state.user?.all_time_rank}
                                  </span>
                                </h5>
                              </li>
                              <li>
                                <h5>
                                  Coins Earned:{" "}
                                  <span className="point">
                                    {this.state.user?.coins}
                                  </span>
                                </h5>
                              </li>
                              <li>
                                <h5>
                                  All Time Score:{" "}
                                  <span className="score">
                                    {this.state.user?.credits}
                                  </span>
                                </h5>
                              </li>
                            </ul>
                          </div>
                          <div className="clearfix"></div>
                          <PublicProfileSlider
                            passedProfiles={this.state.innovations}
                            userId={this.state.userId}
                          />
                        </>
                      )
                    }
                  })()}

                  <Link to={`/`}>
                    <img
                      alt="Back to the Stogo World"
                      title="Back to the Stogo World"
                      className="bakto"
                      src={backtowImg}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withParams(PublicProfile)
